import { useLogout } from "$/api/auth/logout";

import LogOutIcon from "../../icons/LogoutIcon";

export default function LogoutButton() {
  const { mutate } = useLogout();

  return (
    <button
      type="button"
      onClick={() => mutate()}
      className="relative flex h-10 w-full items-center justify-start gap-3 px-3 text-xs text-grey delay-100 duration-200 md:gap-4 md:px-6 md:text-sm lg:text-base"
    >
      <LogOutIcon />
      <span>Déconnexion</span>
    </button>
  );
}
