/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as UserLayoutImport } from './routes/_user-layout'
import { Route as AuthenticationLayoutImport } from './routes/_authentication-layout'

// Create Virtual Routes

const UserLayoutIndexLazyImport = createFileRoute('/_user-layout/')()
const UserLayoutSettingsIndexLazyImport = createFileRoute(
  '/_user-layout/settings/',
)()
const UserLayoutProfileIndexLazyImport = createFileRoute(
  '/_user-layout/profile/',
)()
const AuthenticationLayoutVerifyIndexLazyImport = createFileRoute(
  '/_authentication-layout/verify/',
)()
const AuthenticationLayoutLoginIndexLazyImport = createFileRoute(
  '/_authentication-layout/login/',
)()
const UserLayoutTransverseIncidentsIndexLazyImport = createFileRoute(
  '/_user-layout/transverse/incidents/',
)()
const UserLayoutTransverseDelaysIndexLazyImport = createFileRoute(
  '/_user-layout/transverse/delays/',
)()
const UserLayoutTransverseDamagesIndexLazyImport = createFileRoute(
  '/_user-layout/transverse/damages/',
)()
const UserLayoutTransverseCnrIndexLazyImport = createFileRoute(
  '/_user-layout/transverse/cnr/',
)()
const UserLayoutTransverseActionsIndexLazyImport = createFileRoute(
  '/_user-layout/transverse/actions/',
)()
const UserLayoutSalesStatsIndexLazyImport = createFileRoute(
  '/_user-layout/sales/stats/',
)()
const UserLayoutSalesKpiIndexLazyImport = createFileRoute(
  '/_user-layout/sales/kpi/',
)()
const UserLayoutSalesInvoicesIndexLazyImport = createFileRoute(
  '/_user-layout/sales/invoices/',
)()
const UserLayoutSalesDeliveryRequestIndexLazyImport = createFileRoute(
  '/_user-layout/sales/delivery-request/',
)()
const UserLayoutSalesCompaniesIndexLazyImport = createFileRoute(
  '/_user-layout/sales/companies/',
)()
const UserLayoutSalesAdditionalServicesIndexLazyImport = createFileRoute(
  '/_user-layout/sales/additional-services/',
)()
const UserLayoutMaterialsVehiclesIndexLazyImport = createFileRoute(
  '/_user-layout/materials/vehicles/',
)()
const UserLayoutMaterialsTrailersIndexLazyImport = createFileRoute(
  '/_user-layout/materials/trailers/',
)()
const UserLayoutMaterialsEquipmentIndexLazyImport = createFileRoute(
  '/_user-layout/materials/equipment/',
)()
const UserLayoutJourneyManagementReplacementsIndexLazyImport = createFileRoute(
  '/_user-layout/journey-management/replacements/',
)()
const UserLayoutJourneyManagementModificationsIndexLazyImport = createFileRoute(
  '/_user-layout/journey-management/modifications/',
)()
const UserLayoutJourneyManagementLiveIndexLazyImport = createFileRoute(
  '/_user-layout/journey-management/live/',
)()
const UserLayoutJourneyManagementJourneysIndexLazyImport = createFileRoute(
  '/_user-layout/journey-management/journeys/',
)()
const UserLayoutJourneyManagementDriversPoolsIndexLazyImport = createFileRoute(
  '/_user-layout/journey-management/drivers-pools/',
)()
const UserLayoutJourneyManagementDeliveryRequestIndexLazyImport =
  createFileRoute('/_user-layout/journey-management/delivery-request/')()
const UserLayoutJourneyManagementCalendarIndexLazyImport = createFileRoute(
  '/_user-layout/journey-management/calendar/',
)()
const UserLayoutIncidentsManagementInfractionsIndexLazyImport = createFileRoute(
  '/_user-layout/incidents-management/infractions/',
)()
const UserLayoutIncidentsManagementIncidentsIndexLazyImport = createFileRoute(
  '/_user-layout/incidents-management/incidents/',
)()
const UserLayoutIncidentsManagementDelaysIndexLazyImport = createFileRoute(
  '/_user-layout/incidents-management/delays/',
)()
const UserLayoutIncidentsManagementDamagesIndexLazyImport = createFileRoute(
  '/_user-layout/incidents-management/damages/',
)()
const UserLayoutHumanResourcesDriversIndexLazyImport = createFileRoute(
  '/_user-layout/human-resources/drivers/',
)()
const UserLayoutHumanResourcesDriverCertificationsIndexLazyImport =
  createFileRoute('/_user-layout/human-resources/driver-certifications/')()
const UserLayoutHumanResourcesAbsencesIndexLazyImport = createFileRoute(
  '/_user-layout/human-resources/absences/',
)()
const UserLayoutAdministrationUsersIndexLazyImport = createFileRoute(
  '/_user-layout/administration/users/',
)()
const UserLayoutSalesDeliveryRequestRequestIdIndexLazyImport = createFileRoute(
  '/_user-layout/sales/delivery-request/$requestId/',
)()
const UserLayoutSalesCompaniesCompanyIdIndexLazyImport = createFileRoute(
  '/_user-layout/sales/companies/$companyId/',
)()
const UserLayoutMaterialsVehiclesVehicleIdIndexLazyImport = createFileRoute(
  '/_user-layout/materials/vehicles/$vehicleId/',
)()
const UserLayoutJourneyManagementModificationsHistoryIndexLazyImport =
  createFileRoute('/_user-layout/journey-management/modifications/history/')()
const UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyImport =
  createFileRoute(
    '/_user-layout/journey-management/drivers-pools/$driverPoolId/',
  )()
const UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyImport =
  createFileRoute(
    '/_user-layout/journey-management/delivery-request/$requestId/',
  )()
const UserLayoutHumanResourcesDriversDriverIdIndexLazyImport = createFileRoute(
  '/_user-layout/human-resources/drivers/$driverId/',
)()

// Create/Update Routes

const UserLayoutRoute = UserLayoutImport.update({
  id: '/_user-layout',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticationLayoutRoute = AuthenticationLayoutImport.update({
  id: '/_authentication-layout',
  getParentRoute: () => rootRoute,
} as any)

const UserLayoutIndexLazyRoute = UserLayoutIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => UserLayoutRoute,
} as any).lazy(() =>
  import('./routes/_user-layout/index.lazy').then((d) => d.Route),
)

const UserLayoutSettingsIndexLazyRoute =
  UserLayoutSettingsIndexLazyImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/settings/index.lazy').then((d) => d.Route),
  )

const UserLayoutProfileIndexLazyRoute = UserLayoutProfileIndexLazyImport.update(
  {
    id: '/profile/',
    path: '/profile/',
    getParentRoute: () => UserLayoutRoute,
  } as any,
).lazy(() =>
  import('./routes/_user-layout/profile/index.lazy').then((d) => d.Route),
)

const AuthenticationLayoutVerifyIndexLazyRoute =
  AuthenticationLayoutVerifyIndexLazyImport.update({
    id: '/verify/',
    path: '/verify/',
    getParentRoute: () => AuthenticationLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authentication-layout/verify/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticationLayoutLoginIndexLazyRoute =
  AuthenticationLayoutLoginIndexLazyImport.update({
    id: '/login/',
    path: '/login/',
    getParentRoute: () => AuthenticationLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authentication-layout/login/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutTransverseIncidentsIndexLazyRoute =
  UserLayoutTransverseIncidentsIndexLazyImport.update({
    id: '/transverse/incidents/',
    path: '/transverse/incidents/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/transverse/incidents/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutTransverseDelaysIndexLazyRoute =
  UserLayoutTransverseDelaysIndexLazyImport.update({
    id: '/transverse/delays/',
    path: '/transverse/delays/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/transverse/delays/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutTransverseDamagesIndexLazyRoute =
  UserLayoutTransverseDamagesIndexLazyImport.update({
    id: '/transverse/damages/',
    path: '/transverse/damages/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/transverse/damages/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutTransverseCnrIndexLazyRoute =
  UserLayoutTransverseCnrIndexLazyImport.update({
    id: '/transverse/cnr/',
    path: '/transverse/cnr/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/transverse/cnr/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutTransverseActionsIndexLazyRoute =
  UserLayoutTransverseActionsIndexLazyImport.update({
    id: '/transverse/actions/',
    path: '/transverse/actions/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/transverse/actions/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutSalesStatsIndexLazyRoute =
  UserLayoutSalesStatsIndexLazyImport.update({
    id: '/sales/stats/',
    path: '/sales/stats/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/stats/index.lazy').then((d) => d.Route),
  )

const UserLayoutSalesKpiIndexLazyRoute =
  UserLayoutSalesKpiIndexLazyImport.update({
    id: '/sales/kpi/',
    path: '/sales/kpi/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/kpi/index.lazy').then((d) => d.Route),
  )

const UserLayoutSalesInvoicesIndexLazyRoute =
  UserLayoutSalesInvoicesIndexLazyImport.update({
    id: '/sales/invoices/',
    path: '/sales/invoices/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/invoices/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutSalesDeliveryRequestIndexLazyRoute =
  UserLayoutSalesDeliveryRequestIndexLazyImport.update({
    id: '/sales/delivery-request/',
    path: '/sales/delivery-request/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/delivery-request/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutSalesCompaniesIndexLazyRoute =
  UserLayoutSalesCompaniesIndexLazyImport.update({
    id: '/sales/companies/',
    path: '/sales/companies/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/companies/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutSalesAdditionalServicesIndexLazyRoute =
  UserLayoutSalesAdditionalServicesIndexLazyImport.update({
    id: '/sales/additional-services/',
    path: '/sales/additional-services/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/additional-services/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutMaterialsVehiclesIndexLazyRoute =
  UserLayoutMaterialsVehiclesIndexLazyImport.update({
    id: '/materials/vehicles/',
    path: '/materials/vehicles/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/materials/vehicles/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutMaterialsTrailersIndexLazyRoute =
  UserLayoutMaterialsTrailersIndexLazyImport.update({
    id: '/materials/trailers/',
    path: '/materials/trailers/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/materials/trailers/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutMaterialsEquipmentIndexLazyRoute =
  UserLayoutMaterialsEquipmentIndexLazyImport.update({
    id: '/materials/equipment/',
    path: '/materials/equipment/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/materials/equipment/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutJourneyManagementReplacementsIndexLazyRoute =
  UserLayoutJourneyManagementReplacementsIndexLazyImport.update({
    id: '/journey-management/replacements/',
    path: '/journey-management/replacements/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/replacements/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementModificationsIndexLazyRoute =
  UserLayoutJourneyManagementModificationsIndexLazyImport.update({
    id: '/journey-management/modifications/',
    path: '/journey-management/modifications/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/modifications/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementLiveIndexLazyRoute =
  UserLayoutJourneyManagementLiveIndexLazyImport.update({
    id: '/journey-management/live/',
    path: '/journey-management/live/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/journey-management/live/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutJourneyManagementJourneysIndexLazyRoute =
  UserLayoutJourneyManagementJourneysIndexLazyImport.update({
    id: '/journey-management/journeys/',
    path: '/journey-management/journeys/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/journey-management/journeys/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutJourneyManagementDriversPoolsIndexLazyRoute =
  UserLayoutJourneyManagementDriversPoolsIndexLazyImport.update({
    id: '/journey-management/drivers-pools/',
    path: '/journey-management/drivers-pools/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/drivers-pools/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute =
  UserLayoutJourneyManagementDeliveryRequestIndexLazyImport.update({
    id: '/journey-management/delivery-request/',
    path: '/journey-management/delivery-request/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/delivery-request/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementCalendarIndexLazyRoute =
  UserLayoutJourneyManagementCalendarIndexLazyImport.update({
    id: '/journey-management/calendar/',
    path: '/journey-management/calendar/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/journey-management/calendar/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutIncidentsManagementInfractionsIndexLazyRoute =
  UserLayoutIncidentsManagementInfractionsIndexLazyImport.update({
    id: '/incidents-management/infractions/',
    path: '/incidents-management/infractions/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/incidents-management/infractions/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutIncidentsManagementIncidentsIndexLazyRoute =
  UserLayoutIncidentsManagementIncidentsIndexLazyImport.update({
    id: '/incidents-management/incidents/',
    path: '/incidents-management/incidents/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/incidents-management/incidents/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutIncidentsManagementDelaysIndexLazyRoute =
  UserLayoutIncidentsManagementDelaysIndexLazyImport.update({
    id: '/incidents-management/delays/',
    path: '/incidents-management/delays/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/incidents-management/delays/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutIncidentsManagementDamagesIndexLazyRoute =
  UserLayoutIncidentsManagementDamagesIndexLazyImport.update({
    id: '/incidents-management/damages/',
    path: '/incidents-management/damages/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/incidents-management/damages/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutHumanResourcesDriversIndexLazyRoute =
  UserLayoutHumanResourcesDriversIndexLazyImport.update({
    id: '/human-resources/drivers/',
    path: '/human-resources/drivers/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/human-resources/drivers/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute =
  UserLayoutHumanResourcesDriverCertificationsIndexLazyImport.update({
    id: '/human-resources/driver-certifications/',
    path: '/human-resources/driver-certifications/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/human-resources/driver-certifications/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutHumanResourcesAbsencesIndexLazyRoute =
  UserLayoutHumanResourcesAbsencesIndexLazyImport.update({
    id: '/human-resources/absences/',
    path: '/human-resources/absences/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/human-resources/absences/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutAdministrationUsersIndexLazyRoute =
  UserLayoutAdministrationUsersIndexLazyImport.update({
    id: '/administration/users/',
    path: '/administration/users/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/administration/users/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute =
  UserLayoutSalesDeliveryRequestRequestIdIndexLazyImport.update({
    id: '/sales/delivery-request/$requestId/',
    path: '/sales/delivery-request/$requestId/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/sales/delivery-request/$requestId/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutSalesCompaniesCompanyIdIndexLazyRoute =
  UserLayoutSalesCompaniesCompanyIdIndexLazyImport.update({
    id: '/sales/companies/$companyId/',
    path: '/sales/companies/$companyId/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_user-layout/sales/companies/$companyId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute =
  UserLayoutMaterialsVehiclesVehicleIdIndexLazyImport.update({
    id: '/materials/vehicles/$vehicleId/',
    path: '/materials/vehicles/$vehicleId/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/materials/vehicles/$vehicleId/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute =
  UserLayoutJourneyManagementModificationsHistoryIndexLazyImport.update({
    id: '/journey-management/modifications/history/',
    path: '/journey-management/modifications/history/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/modifications/history/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute =
  UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyImport.update({
    id: '/journey-management/drivers-pools/$driverPoolId/',
    path: '/journey-management/drivers-pools/$driverPoolId/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/drivers-pools/$driverPoolId/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute =
  UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyImport.update({
    id: '/journey-management/delivery-request/$requestId/',
    path: '/journey-management/delivery-request/$requestId/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/journey-management/delivery-request/$requestId/index.lazy'
    ).then((d) => d.Route),
  )

const UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute =
  UserLayoutHumanResourcesDriversDriverIdIndexLazyImport.update({
    id: '/human-resources/drivers/$driverId/',
    path: '/human-resources/drivers/$driverId/',
    getParentRoute: () => UserLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_user-layout/human-resources/drivers/$driverId/index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authentication-layout': {
      id: '/_authentication-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticationLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_user-layout': {
      id: '/_user-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UserLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_user-layout/': {
      id: '/_user-layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof UserLayoutIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_authentication-layout/login/': {
      id: '/_authentication-layout/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthenticationLayoutLoginIndexLazyImport
      parentRoute: typeof AuthenticationLayoutImport
    }
    '/_authentication-layout/verify/': {
      id: '/_authentication-layout/verify/'
      path: '/verify'
      fullPath: '/verify'
      preLoaderRoute: typeof AuthenticationLayoutVerifyIndexLazyImport
      parentRoute: typeof AuthenticationLayoutImport
    }
    '/_user-layout/profile/': {
      id: '/_user-layout/profile/'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof UserLayoutProfileIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/settings/': {
      id: '/_user-layout/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof UserLayoutSettingsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/administration/users/': {
      id: '/_user-layout/administration/users/'
      path: '/administration/users'
      fullPath: '/administration/users'
      preLoaderRoute: typeof UserLayoutAdministrationUsersIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/human-resources/absences/': {
      id: '/_user-layout/human-resources/absences/'
      path: '/human-resources/absences'
      fullPath: '/human-resources/absences'
      preLoaderRoute: typeof UserLayoutHumanResourcesAbsencesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/human-resources/driver-certifications/': {
      id: '/_user-layout/human-resources/driver-certifications/'
      path: '/human-resources/driver-certifications'
      fullPath: '/human-resources/driver-certifications'
      preLoaderRoute: typeof UserLayoutHumanResourcesDriverCertificationsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/human-resources/drivers/': {
      id: '/_user-layout/human-resources/drivers/'
      path: '/human-resources/drivers'
      fullPath: '/human-resources/drivers'
      preLoaderRoute: typeof UserLayoutHumanResourcesDriversIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/incidents-management/damages/': {
      id: '/_user-layout/incidents-management/damages/'
      path: '/incidents-management/damages'
      fullPath: '/incidents-management/damages'
      preLoaderRoute: typeof UserLayoutIncidentsManagementDamagesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/incidents-management/delays/': {
      id: '/_user-layout/incidents-management/delays/'
      path: '/incidents-management/delays'
      fullPath: '/incidents-management/delays'
      preLoaderRoute: typeof UserLayoutIncidentsManagementDelaysIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/incidents-management/incidents/': {
      id: '/_user-layout/incidents-management/incidents/'
      path: '/incidents-management/incidents'
      fullPath: '/incidents-management/incidents'
      preLoaderRoute: typeof UserLayoutIncidentsManagementIncidentsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/incidents-management/infractions/': {
      id: '/_user-layout/incidents-management/infractions/'
      path: '/incidents-management/infractions'
      fullPath: '/incidents-management/infractions'
      preLoaderRoute: typeof UserLayoutIncidentsManagementInfractionsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/calendar/': {
      id: '/_user-layout/journey-management/calendar/'
      path: '/journey-management/calendar'
      fullPath: '/journey-management/calendar'
      preLoaderRoute: typeof UserLayoutJourneyManagementCalendarIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/delivery-request/': {
      id: '/_user-layout/journey-management/delivery-request/'
      path: '/journey-management/delivery-request'
      fullPath: '/journey-management/delivery-request'
      preLoaderRoute: typeof UserLayoutJourneyManagementDeliveryRequestIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/drivers-pools/': {
      id: '/_user-layout/journey-management/drivers-pools/'
      path: '/journey-management/drivers-pools'
      fullPath: '/journey-management/drivers-pools'
      preLoaderRoute: typeof UserLayoutJourneyManagementDriversPoolsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/journeys/': {
      id: '/_user-layout/journey-management/journeys/'
      path: '/journey-management/journeys'
      fullPath: '/journey-management/journeys'
      preLoaderRoute: typeof UserLayoutJourneyManagementJourneysIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/live/': {
      id: '/_user-layout/journey-management/live/'
      path: '/journey-management/live'
      fullPath: '/journey-management/live'
      preLoaderRoute: typeof UserLayoutJourneyManagementLiveIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/modifications/': {
      id: '/_user-layout/journey-management/modifications/'
      path: '/journey-management/modifications'
      fullPath: '/journey-management/modifications'
      preLoaderRoute: typeof UserLayoutJourneyManagementModificationsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/replacements/': {
      id: '/_user-layout/journey-management/replacements/'
      path: '/journey-management/replacements'
      fullPath: '/journey-management/replacements'
      preLoaderRoute: typeof UserLayoutJourneyManagementReplacementsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/materials/equipment/': {
      id: '/_user-layout/materials/equipment/'
      path: '/materials/equipment'
      fullPath: '/materials/equipment'
      preLoaderRoute: typeof UserLayoutMaterialsEquipmentIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/materials/trailers/': {
      id: '/_user-layout/materials/trailers/'
      path: '/materials/trailers'
      fullPath: '/materials/trailers'
      preLoaderRoute: typeof UserLayoutMaterialsTrailersIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/materials/vehicles/': {
      id: '/_user-layout/materials/vehicles/'
      path: '/materials/vehicles'
      fullPath: '/materials/vehicles'
      preLoaderRoute: typeof UserLayoutMaterialsVehiclesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/additional-services/': {
      id: '/_user-layout/sales/additional-services/'
      path: '/sales/additional-services'
      fullPath: '/sales/additional-services'
      preLoaderRoute: typeof UserLayoutSalesAdditionalServicesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/companies/': {
      id: '/_user-layout/sales/companies/'
      path: '/sales/companies'
      fullPath: '/sales/companies'
      preLoaderRoute: typeof UserLayoutSalesCompaniesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/delivery-request/': {
      id: '/_user-layout/sales/delivery-request/'
      path: '/sales/delivery-request'
      fullPath: '/sales/delivery-request'
      preLoaderRoute: typeof UserLayoutSalesDeliveryRequestIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/invoices/': {
      id: '/_user-layout/sales/invoices/'
      path: '/sales/invoices'
      fullPath: '/sales/invoices'
      preLoaderRoute: typeof UserLayoutSalesInvoicesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/kpi/': {
      id: '/_user-layout/sales/kpi/'
      path: '/sales/kpi'
      fullPath: '/sales/kpi'
      preLoaderRoute: typeof UserLayoutSalesKpiIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/stats/': {
      id: '/_user-layout/sales/stats/'
      path: '/sales/stats'
      fullPath: '/sales/stats'
      preLoaderRoute: typeof UserLayoutSalesStatsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/transverse/actions/': {
      id: '/_user-layout/transverse/actions/'
      path: '/transverse/actions'
      fullPath: '/transverse/actions'
      preLoaderRoute: typeof UserLayoutTransverseActionsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/transverse/cnr/': {
      id: '/_user-layout/transverse/cnr/'
      path: '/transverse/cnr'
      fullPath: '/transverse/cnr'
      preLoaderRoute: typeof UserLayoutTransverseCnrIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/transverse/damages/': {
      id: '/_user-layout/transverse/damages/'
      path: '/transverse/damages'
      fullPath: '/transverse/damages'
      preLoaderRoute: typeof UserLayoutTransverseDamagesIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/transverse/delays/': {
      id: '/_user-layout/transverse/delays/'
      path: '/transverse/delays'
      fullPath: '/transverse/delays'
      preLoaderRoute: typeof UserLayoutTransverseDelaysIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/transverse/incidents/': {
      id: '/_user-layout/transverse/incidents/'
      path: '/transverse/incidents'
      fullPath: '/transverse/incidents'
      preLoaderRoute: typeof UserLayoutTransverseIncidentsIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/human-resources/drivers/$driverId/': {
      id: '/_user-layout/human-resources/drivers/$driverId/'
      path: '/human-resources/drivers/$driverId'
      fullPath: '/human-resources/drivers/$driverId'
      preLoaderRoute: typeof UserLayoutHumanResourcesDriversDriverIdIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/delivery-request/$requestId/': {
      id: '/_user-layout/journey-management/delivery-request/$requestId/'
      path: '/journey-management/delivery-request/$requestId'
      fullPath: '/journey-management/delivery-request/$requestId'
      preLoaderRoute: typeof UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/drivers-pools/$driverPoolId/': {
      id: '/_user-layout/journey-management/drivers-pools/$driverPoolId/'
      path: '/journey-management/drivers-pools/$driverPoolId'
      fullPath: '/journey-management/drivers-pools/$driverPoolId'
      preLoaderRoute: typeof UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/journey-management/modifications/history/': {
      id: '/_user-layout/journey-management/modifications/history/'
      path: '/journey-management/modifications/history'
      fullPath: '/journey-management/modifications/history'
      preLoaderRoute: typeof UserLayoutJourneyManagementModificationsHistoryIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/materials/vehicles/$vehicleId/': {
      id: '/_user-layout/materials/vehicles/$vehicleId/'
      path: '/materials/vehicles/$vehicleId'
      fullPath: '/materials/vehicles/$vehicleId'
      preLoaderRoute: typeof UserLayoutMaterialsVehiclesVehicleIdIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/companies/$companyId/': {
      id: '/_user-layout/sales/companies/$companyId/'
      path: '/sales/companies/$companyId'
      fullPath: '/sales/companies/$companyId'
      preLoaderRoute: typeof UserLayoutSalesCompaniesCompanyIdIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
    '/_user-layout/sales/delivery-request/$requestId/': {
      id: '/_user-layout/sales/delivery-request/$requestId/'
      path: '/sales/delivery-request/$requestId'
      fullPath: '/sales/delivery-request/$requestId'
      preLoaderRoute: typeof UserLayoutSalesDeliveryRequestRequestIdIndexLazyImport
      parentRoute: typeof UserLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthenticationLayoutRouteChildren {
  AuthenticationLayoutLoginIndexLazyRoute: typeof AuthenticationLayoutLoginIndexLazyRoute
  AuthenticationLayoutVerifyIndexLazyRoute: typeof AuthenticationLayoutVerifyIndexLazyRoute
}

const AuthenticationLayoutRouteChildren: AuthenticationLayoutRouteChildren = {
  AuthenticationLayoutLoginIndexLazyRoute:
    AuthenticationLayoutLoginIndexLazyRoute,
  AuthenticationLayoutVerifyIndexLazyRoute:
    AuthenticationLayoutVerifyIndexLazyRoute,
}

const AuthenticationLayoutRouteWithChildren =
  AuthenticationLayoutRoute._addFileChildren(AuthenticationLayoutRouteChildren)

interface UserLayoutRouteChildren {
  UserLayoutIndexLazyRoute: typeof UserLayoutIndexLazyRoute
  UserLayoutProfileIndexLazyRoute: typeof UserLayoutProfileIndexLazyRoute
  UserLayoutSettingsIndexLazyRoute: typeof UserLayoutSettingsIndexLazyRoute
  UserLayoutAdministrationUsersIndexLazyRoute: typeof UserLayoutAdministrationUsersIndexLazyRoute
  UserLayoutHumanResourcesAbsencesIndexLazyRoute: typeof UserLayoutHumanResourcesAbsencesIndexLazyRoute
  UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute: typeof UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute
  UserLayoutHumanResourcesDriversIndexLazyRoute: typeof UserLayoutHumanResourcesDriversIndexLazyRoute
  UserLayoutIncidentsManagementDamagesIndexLazyRoute: typeof UserLayoutIncidentsManagementDamagesIndexLazyRoute
  UserLayoutIncidentsManagementDelaysIndexLazyRoute: typeof UserLayoutIncidentsManagementDelaysIndexLazyRoute
  UserLayoutIncidentsManagementIncidentsIndexLazyRoute: typeof UserLayoutIncidentsManagementIncidentsIndexLazyRoute
  UserLayoutIncidentsManagementInfractionsIndexLazyRoute: typeof UserLayoutIncidentsManagementInfractionsIndexLazyRoute
  UserLayoutJourneyManagementCalendarIndexLazyRoute: typeof UserLayoutJourneyManagementCalendarIndexLazyRoute
  UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute: typeof UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute
  UserLayoutJourneyManagementDriversPoolsIndexLazyRoute: typeof UserLayoutJourneyManagementDriversPoolsIndexLazyRoute
  UserLayoutJourneyManagementJourneysIndexLazyRoute: typeof UserLayoutJourneyManagementJourneysIndexLazyRoute
  UserLayoutJourneyManagementLiveIndexLazyRoute: typeof UserLayoutJourneyManagementLiveIndexLazyRoute
  UserLayoutJourneyManagementModificationsIndexLazyRoute: typeof UserLayoutJourneyManagementModificationsIndexLazyRoute
  UserLayoutJourneyManagementReplacementsIndexLazyRoute: typeof UserLayoutJourneyManagementReplacementsIndexLazyRoute
  UserLayoutMaterialsEquipmentIndexLazyRoute: typeof UserLayoutMaterialsEquipmentIndexLazyRoute
  UserLayoutMaterialsTrailersIndexLazyRoute: typeof UserLayoutMaterialsTrailersIndexLazyRoute
  UserLayoutMaterialsVehiclesIndexLazyRoute: typeof UserLayoutMaterialsVehiclesIndexLazyRoute
  UserLayoutSalesAdditionalServicesIndexLazyRoute: typeof UserLayoutSalesAdditionalServicesIndexLazyRoute
  UserLayoutSalesCompaniesIndexLazyRoute: typeof UserLayoutSalesCompaniesIndexLazyRoute
  UserLayoutSalesDeliveryRequestIndexLazyRoute: typeof UserLayoutSalesDeliveryRequestIndexLazyRoute
  UserLayoutSalesInvoicesIndexLazyRoute: typeof UserLayoutSalesInvoicesIndexLazyRoute
  UserLayoutSalesKpiIndexLazyRoute: typeof UserLayoutSalesKpiIndexLazyRoute
  UserLayoutSalesStatsIndexLazyRoute: typeof UserLayoutSalesStatsIndexLazyRoute
  UserLayoutTransverseActionsIndexLazyRoute: typeof UserLayoutTransverseActionsIndexLazyRoute
  UserLayoutTransverseCnrIndexLazyRoute: typeof UserLayoutTransverseCnrIndexLazyRoute
  UserLayoutTransverseDamagesIndexLazyRoute: typeof UserLayoutTransverseDamagesIndexLazyRoute
  UserLayoutTransverseDelaysIndexLazyRoute: typeof UserLayoutTransverseDelaysIndexLazyRoute
  UserLayoutTransverseIncidentsIndexLazyRoute: typeof UserLayoutTransverseIncidentsIndexLazyRoute
  UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute: typeof UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute
  UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute: typeof UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute
  UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute: typeof UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute
  UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute: typeof UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute
  UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute: typeof UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute
  UserLayoutSalesCompaniesCompanyIdIndexLazyRoute: typeof UserLayoutSalesCompaniesCompanyIdIndexLazyRoute
  UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute: typeof UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute
}

const UserLayoutRouteChildren: UserLayoutRouteChildren = {
  UserLayoutIndexLazyRoute: UserLayoutIndexLazyRoute,
  UserLayoutProfileIndexLazyRoute: UserLayoutProfileIndexLazyRoute,
  UserLayoutSettingsIndexLazyRoute: UserLayoutSettingsIndexLazyRoute,
  UserLayoutAdministrationUsersIndexLazyRoute:
    UserLayoutAdministrationUsersIndexLazyRoute,
  UserLayoutHumanResourcesAbsencesIndexLazyRoute:
    UserLayoutHumanResourcesAbsencesIndexLazyRoute,
  UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute:
    UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute,
  UserLayoutHumanResourcesDriversIndexLazyRoute:
    UserLayoutHumanResourcesDriversIndexLazyRoute,
  UserLayoutIncidentsManagementDamagesIndexLazyRoute:
    UserLayoutIncidentsManagementDamagesIndexLazyRoute,
  UserLayoutIncidentsManagementDelaysIndexLazyRoute:
    UserLayoutIncidentsManagementDelaysIndexLazyRoute,
  UserLayoutIncidentsManagementIncidentsIndexLazyRoute:
    UserLayoutIncidentsManagementIncidentsIndexLazyRoute,
  UserLayoutIncidentsManagementInfractionsIndexLazyRoute:
    UserLayoutIncidentsManagementInfractionsIndexLazyRoute,
  UserLayoutJourneyManagementCalendarIndexLazyRoute:
    UserLayoutJourneyManagementCalendarIndexLazyRoute,
  UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute:
    UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute,
  UserLayoutJourneyManagementDriversPoolsIndexLazyRoute:
    UserLayoutJourneyManagementDriversPoolsIndexLazyRoute,
  UserLayoutJourneyManagementJourneysIndexLazyRoute:
    UserLayoutJourneyManagementJourneysIndexLazyRoute,
  UserLayoutJourneyManagementLiveIndexLazyRoute:
    UserLayoutJourneyManagementLiveIndexLazyRoute,
  UserLayoutJourneyManagementModificationsIndexLazyRoute:
    UserLayoutJourneyManagementModificationsIndexLazyRoute,
  UserLayoutJourneyManagementReplacementsIndexLazyRoute:
    UserLayoutJourneyManagementReplacementsIndexLazyRoute,
  UserLayoutMaterialsEquipmentIndexLazyRoute:
    UserLayoutMaterialsEquipmentIndexLazyRoute,
  UserLayoutMaterialsTrailersIndexLazyRoute:
    UserLayoutMaterialsTrailersIndexLazyRoute,
  UserLayoutMaterialsVehiclesIndexLazyRoute:
    UserLayoutMaterialsVehiclesIndexLazyRoute,
  UserLayoutSalesAdditionalServicesIndexLazyRoute:
    UserLayoutSalesAdditionalServicesIndexLazyRoute,
  UserLayoutSalesCompaniesIndexLazyRoute:
    UserLayoutSalesCompaniesIndexLazyRoute,
  UserLayoutSalesDeliveryRequestIndexLazyRoute:
    UserLayoutSalesDeliveryRequestIndexLazyRoute,
  UserLayoutSalesInvoicesIndexLazyRoute: UserLayoutSalesInvoicesIndexLazyRoute,
  UserLayoutSalesKpiIndexLazyRoute: UserLayoutSalesKpiIndexLazyRoute,
  UserLayoutSalesStatsIndexLazyRoute: UserLayoutSalesStatsIndexLazyRoute,
  UserLayoutTransverseActionsIndexLazyRoute:
    UserLayoutTransverseActionsIndexLazyRoute,
  UserLayoutTransverseCnrIndexLazyRoute: UserLayoutTransverseCnrIndexLazyRoute,
  UserLayoutTransverseDamagesIndexLazyRoute:
    UserLayoutTransverseDamagesIndexLazyRoute,
  UserLayoutTransverseDelaysIndexLazyRoute:
    UserLayoutTransverseDelaysIndexLazyRoute,
  UserLayoutTransverseIncidentsIndexLazyRoute:
    UserLayoutTransverseIncidentsIndexLazyRoute,
  UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute:
    UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute,
  UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute:
    UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute,
  UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute:
    UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute,
  UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute:
    UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute,
  UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute:
    UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute,
  UserLayoutSalesCompaniesCompanyIdIndexLazyRoute:
    UserLayoutSalesCompaniesCompanyIdIndexLazyRoute,
  UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute:
    UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute,
}

const UserLayoutRouteWithChildren = UserLayoutRoute._addFileChildren(
  UserLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof UserLayoutRouteWithChildren
  '/': typeof UserLayoutIndexLazyRoute
  '/login': typeof AuthenticationLayoutLoginIndexLazyRoute
  '/verify': typeof AuthenticationLayoutVerifyIndexLazyRoute
  '/profile': typeof UserLayoutProfileIndexLazyRoute
  '/settings': typeof UserLayoutSettingsIndexLazyRoute
  '/administration/users': typeof UserLayoutAdministrationUsersIndexLazyRoute
  '/human-resources/absences': typeof UserLayoutHumanResourcesAbsencesIndexLazyRoute
  '/human-resources/driver-certifications': typeof UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute
  '/human-resources/drivers': typeof UserLayoutHumanResourcesDriversIndexLazyRoute
  '/incidents-management/damages': typeof UserLayoutIncidentsManagementDamagesIndexLazyRoute
  '/incidents-management/delays': typeof UserLayoutIncidentsManagementDelaysIndexLazyRoute
  '/incidents-management/incidents': typeof UserLayoutIncidentsManagementIncidentsIndexLazyRoute
  '/incidents-management/infractions': typeof UserLayoutIncidentsManagementInfractionsIndexLazyRoute
  '/journey-management/calendar': typeof UserLayoutJourneyManagementCalendarIndexLazyRoute
  '/journey-management/delivery-request': typeof UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute
  '/journey-management/drivers-pools': typeof UserLayoutJourneyManagementDriversPoolsIndexLazyRoute
  '/journey-management/journeys': typeof UserLayoutJourneyManagementJourneysIndexLazyRoute
  '/journey-management/live': typeof UserLayoutJourneyManagementLiveIndexLazyRoute
  '/journey-management/modifications': typeof UserLayoutJourneyManagementModificationsIndexLazyRoute
  '/journey-management/replacements': typeof UserLayoutJourneyManagementReplacementsIndexLazyRoute
  '/materials/equipment': typeof UserLayoutMaterialsEquipmentIndexLazyRoute
  '/materials/trailers': typeof UserLayoutMaterialsTrailersIndexLazyRoute
  '/materials/vehicles': typeof UserLayoutMaterialsVehiclesIndexLazyRoute
  '/sales/additional-services': typeof UserLayoutSalesAdditionalServicesIndexLazyRoute
  '/sales/companies': typeof UserLayoutSalesCompaniesIndexLazyRoute
  '/sales/delivery-request': typeof UserLayoutSalesDeliveryRequestIndexLazyRoute
  '/sales/invoices': typeof UserLayoutSalesInvoicesIndexLazyRoute
  '/sales/kpi': typeof UserLayoutSalesKpiIndexLazyRoute
  '/sales/stats': typeof UserLayoutSalesStatsIndexLazyRoute
  '/transverse/actions': typeof UserLayoutTransverseActionsIndexLazyRoute
  '/transverse/cnr': typeof UserLayoutTransverseCnrIndexLazyRoute
  '/transverse/damages': typeof UserLayoutTransverseDamagesIndexLazyRoute
  '/transverse/delays': typeof UserLayoutTransverseDelaysIndexLazyRoute
  '/transverse/incidents': typeof UserLayoutTransverseIncidentsIndexLazyRoute
  '/human-resources/drivers/$driverId': typeof UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute
  '/journey-management/delivery-request/$requestId': typeof UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute
  '/journey-management/drivers-pools/$driverPoolId': typeof UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute
  '/journey-management/modifications/history': typeof UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute
  '/materials/vehicles/$vehicleId': typeof UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute
  '/sales/companies/$companyId': typeof UserLayoutSalesCompaniesCompanyIdIndexLazyRoute
  '/sales/delivery-request/$requestId': typeof UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticationLayoutRouteWithChildren
  '/': typeof UserLayoutIndexLazyRoute
  '/login': typeof AuthenticationLayoutLoginIndexLazyRoute
  '/verify': typeof AuthenticationLayoutVerifyIndexLazyRoute
  '/profile': typeof UserLayoutProfileIndexLazyRoute
  '/settings': typeof UserLayoutSettingsIndexLazyRoute
  '/administration/users': typeof UserLayoutAdministrationUsersIndexLazyRoute
  '/human-resources/absences': typeof UserLayoutHumanResourcesAbsencesIndexLazyRoute
  '/human-resources/driver-certifications': typeof UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute
  '/human-resources/drivers': typeof UserLayoutHumanResourcesDriversIndexLazyRoute
  '/incidents-management/damages': typeof UserLayoutIncidentsManagementDamagesIndexLazyRoute
  '/incidents-management/delays': typeof UserLayoutIncidentsManagementDelaysIndexLazyRoute
  '/incidents-management/incidents': typeof UserLayoutIncidentsManagementIncidentsIndexLazyRoute
  '/incidents-management/infractions': typeof UserLayoutIncidentsManagementInfractionsIndexLazyRoute
  '/journey-management/calendar': typeof UserLayoutJourneyManagementCalendarIndexLazyRoute
  '/journey-management/delivery-request': typeof UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute
  '/journey-management/drivers-pools': typeof UserLayoutJourneyManagementDriversPoolsIndexLazyRoute
  '/journey-management/journeys': typeof UserLayoutJourneyManagementJourneysIndexLazyRoute
  '/journey-management/live': typeof UserLayoutJourneyManagementLiveIndexLazyRoute
  '/journey-management/modifications': typeof UserLayoutJourneyManagementModificationsIndexLazyRoute
  '/journey-management/replacements': typeof UserLayoutJourneyManagementReplacementsIndexLazyRoute
  '/materials/equipment': typeof UserLayoutMaterialsEquipmentIndexLazyRoute
  '/materials/trailers': typeof UserLayoutMaterialsTrailersIndexLazyRoute
  '/materials/vehicles': typeof UserLayoutMaterialsVehiclesIndexLazyRoute
  '/sales/additional-services': typeof UserLayoutSalesAdditionalServicesIndexLazyRoute
  '/sales/companies': typeof UserLayoutSalesCompaniesIndexLazyRoute
  '/sales/delivery-request': typeof UserLayoutSalesDeliveryRequestIndexLazyRoute
  '/sales/invoices': typeof UserLayoutSalesInvoicesIndexLazyRoute
  '/sales/kpi': typeof UserLayoutSalesKpiIndexLazyRoute
  '/sales/stats': typeof UserLayoutSalesStatsIndexLazyRoute
  '/transverse/actions': typeof UserLayoutTransverseActionsIndexLazyRoute
  '/transverse/cnr': typeof UserLayoutTransverseCnrIndexLazyRoute
  '/transverse/damages': typeof UserLayoutTransverseDamagesIndexLazyRoute
  '/transverse/delays': typeof UserLayoutTransverseDelaysIndexLazyRoute
  '/transverse/incidents': typeof UserLayoutTransverseIncidentsIndexLazyRoute
  '/human-resources/drivers/$driverId': typeof UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute
  '/journey-management/delivery-request/$requestId': typeof UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute
  '/journey-management/drivers-pools/$driverPoolId': typeof UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute
  '/journey-management/modifications/history': typeof UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute
  '/materials/vehicles/$vehicleId': typeof UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute
  '/sales/companies/$companyId': typeof UserLayoutSalesCompaniesCompanyIdIndexLazyRoute
  '/sales/delivery-request/$requestId': typeof UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authentication-layout': typeof AuthenticationLayoutRouteWithChildren
  '/_user-layout': typeof UserLayoutRouteWithChildren
  '/_user-layout/': typeof UserLayoutIndexLazyRoute
  '/_authentication-layout/login/': typeof AuthenticationLayoutLoginIndexLazyRoute
  '/_authentication-layout/verify/': typeof AuthenticationLayoutVerifyIndexLazyRoute
  '/_user-layout/profile/': typeof UserLayoutProfileIndexLazyRoute
  '/_user-layout/settings/': typeof UserLayoutSettingsIndexLazyRoute
  '/_user-layout/administration/users/': typeof UserLayoutAdministrationUsersIndexLazyRoute
  '/_user-layout/human-resources/absences/': typeof UserLayoutHumanResourcesAbsencesIndexLazyRoute
  '/_user-layout/human-resources/driver-certifications/': typeof UserLayoutHumanResourcesDriverCertificationsIndexLazyRoute
  '/_user-layout/human-resources/drivers/': typeof UserLayoutHumanResourcesDriversIndexLazyRoute
  '/_user-layout/incidents-management/damages/': typeof UserLayoutIncidentsManagementDamagesIndexLazyRoute
  '/_user-layout/incidents-management/delays/': typeof UserLayoutIncidentsManagementDelaysIndexLazyRoute
  '/_user-layout/incidents-management/incidents/': typeof UserLayoutIncidentsManagementIncidentsIndexLazyRoute
  '/_user-layout/incidents-management/infractions/': typeof UserLayoutIncidentsManagementInfractionsIndexLazyRoute
  '/_user-layout/journey-management/calendar/': typeof UserLayoutJourneyManagementCalendarIndexLazyRoute
  '/_user-layout/journey-management/delivery-request/': typeof UserLayoutJourneyManagementDeliveryRequestIndexLazyRoute
  '/_user-layout/journey-management/drivers-pools/': typeof UserLayoutJourneyManagementDriversPoolsIndexLazyRoute
  '/_user-layout/journey-management/journeys/': typeof UserLayoutJourneyManagementJourneysIndexLazyRoute
  '/_user-layout/journey-management/live/': typeof UserLayoutJourneyManagementLiveIndexLazyRoute
  '/_user-layout/journey-management/modifications/': typeof UserLayoutJourneyManagementModificationsIndexLazyRoute
  '/_user-layout/journey-management/replacements/': typeof UserLayoutJourneyManagementReplacementsIndexLazyRoute
  '/_user-layout/materials/equipment/': typeof UserLayoutMaterialsEquipmentIndexLazyRoute
  '/_user-layout/materials/trailers/': typeof UserLayoutMaterialsTrailersIndexLazyRoute
  '/_user-layout/materials/vehicles/': typeof UserLayoutMaterialsVehiclesIndexLazyRoute
  '/_user-layout/sales/additional-services/': typeof UserLayoutSalesAdditionalServicesIndexLazyRoute
  '/_user-layout/sales/companies/': typeof UserLayoutSalesCompaniesIndexLazyRoute
  '/_user-layout/sales/delivery-request/': typeof UserLayoutSalesDeliveryRequestIndexLazyRoute
  '/_user-layout/sales/invoices/': typeof UserLayoutSalesInvoicesIndexLazyRoute
  '/_user-layout/sales/kpi/': typeof UserLayoutSalesKpiIndexLazyRoute
  '/_user-layout/sales/stats/': typeof UserLayoutSalesStatsIndexLazyRoute
  '/_user-layout/transverse/actions/': typeof UserLayoutTransverseActionsIndexLazyRoute
  '/_user-layout/transverse/cnr/': typeof UserLayoutTransverseCnrIndexLazyRoute
  '/_user-layout/transverse/damages/': typeof UserLayoutTransverseDamagesIndexLazyRoute
  '/_user-layout/transverse/delays/': typeof UserLayoutTransverseDelaysIndexLazyRoute
  '/_user-layout/transverse/incidents/': typeof UserLayoutTransverseIncidentsIndexLazyRoute
  '/_user-layout/human-resources/drivers/$driverId/': typeof UserLayoutHumanResourcesDriversDriverIdIndexLazyRoute
  '/_user-layout/journey-management/delivery-request/$requestId/': typeof UserLayoutJourneyManagementDeliveryRequestRequestIdIndexLazyRoute
  '/_user-layout/journey-management/drivers-pools/$driverPoolId/': typeof UserLayoutJourneyManagementDriversPoolsDriverPoolIdIndexLazyRoute
  '/_user-layout/journey-management/modifications/history/': typeof UserLayoutJourneyManagementModificationsHistoryIndexLazyRoute
  '/_user-layout/materials/vehicles/$vehicleId/': typeof UserLayoutMaterialsVehiclesVehicleIdIndexLazyRoute
  '/_user-layout/sales/companies/$companyId/': typeof UserLayoutSalesCompaniesCompanyIdIndexLazyRoute
  '/_user-layout/sales/delivery-request/$requestId/': typeof UserLayoutSalesDeliveryRequestRequestIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/'
    | '/login'
    | '/verify'
    | '/profile'
    | '/settings'
    | '/administration/users'
    | '/human-resources/absences'
    | '/human-resources/driver-certifications'
    | '/human-resources/drivers'
    | '/incidents-management/damages'
    | '/incidents-management/delays'
    | '/incidents-management/incidents'
    | '/incidents-management/infractions'
    | '/journey-management/calendar'
    | '/journey-management/delivery-request'
    | '/journey-management/drivers-pools'
    | '/journey-management/journeys'
    | '/journey-management/live'
    | '/journey-management/modifications'
    | '/journey-management/replacements'
    | '/materials/equipment'
    | '/materials/trailers'
    | '/materials/vehicles'
    | '/sales/additional-services'
    | '/sales/companies'
    | '/sales/delivery-request'
    | '/sales/invoices'
    | '/sales/kpi'
    | '/sales/stats'
    | '/transverse/actions'
    | '/transverse/cnr'
    | '/transverse/damages'
    | '/transverse/delays'
    | '/transverse/incidents'
    | '/human-resources/drivers/$driverId'
    | '/journey-management/delivery-request/$requestId'
    | '/journey-management/drivers-pools/$driverPoolId'
    | '/journey-management/modifications/history'
    | '/materials/vehicles/$vehicleId'
    | '/sales/companies/$companyId'
    | '/sales/delivery-request/$requestId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/'
    | '/login'
    | '/verify'
    | '/profile'
    | '/settings'
    | '/administration/users'
    | '/human-resources/absences'
    | '/human-resources/driver-certifications'
    | '/human-resources/drivers'
    | '/incidents-management/damages'
    | '/incidents-management/delays'
    | '/incidents-management/incidents'
    | '/incidents-management/infractions'
    | '/journey-management/calendar'
    | '/journey-management/delivery-request'
    | '/journey-management/drivers-pools'
    | '/journey-management/journeys'
    | '/journey-management/live'
    | '/journey-management/modifications'
    | '/journey-management/replacements'
    | '/materials/equipment'
    | '/materials/trailers'
    | '/materials/vehicles'
    | '/sales/additional-services'
    | '/sales/companies'
    | '/sales/delivery-request'
    | '/sales/invoices'
    | '/sales/kpi'
    | '/sales/stats'
    | '/transverse/actions'
    | '/transverse/cnr'
    | '/transverse/damages'
    | '/transverse/delays'
    | '/transverse/incidents'
    | '/human-resources/drivers/$driverId'
    | '/journey-management/delivery-request/$requestId'
    | '/journey-management/drivers-pools/$driverPoolId'
    | '/journey-management/modifications/history'
    | '/materials/vehicles/$vehicleId'
    | '/sales/companies/$companyId'
    | '/sales/delivery-request/$requestId'
  id:
    | '__root__'
    | '/_authentication-layout'
    | '/_user-layout'
    | '/_user-layout/'
    | '/_authentication-layout/login/'
    | '/_authentication-layout/verify/'
    | '/_user-layout/profile/'
    | '/_user-layout/settings/'
    | '/_user-layout/administration/users/'
    | '/_user-layout/human-resources/absences/'
    | '/_user-layout/human-resources/driver-certifications/'
    | '/_user-layout/human-resources/drivers/'
    | '/_user-layout/incidents-management/damages/'
    | '/_user-layout/incidents-management/delays/'
    | '/_user-layout/incidents-management/incidents/'
    | '/_user-layout/incidents-management/infractions/'
    | '/_user-layout/journey-management/calendar/'
    | '/_user-layout/journey-management/delivery-request/'
    | '/_user-layout/journey-management/drivers-pools/'
    | '/_user-layout/journey-management/journeys/'
    | '/_user-layout/journey-management/live/'
    | '/_user-layout/journey-management/modifications/'
    | '/_user-layout/journey-management/replacements/'
    | '/_user-layout/materials/equipment/'
    | '/_user-layout/materials/trailers/'
    | '/_user-layout/materials/vehicles/'
    | '/_user-layout/sales/additional-services/'
    | '/_user-layout/sales/companies/'
    | '/_user-layout/sales/delivery-request/'
    | '/_user-layout/sales/invoices/'
    | '/_user-layout/sales/kpi/'
    | '/_user-layout/sales/stats/'
    | '/_user-layout/transverse/actions/'
    | '/_user-layout/transverse/cnr/'
    | '/_user-layout/transverse/damages/'
    | '/_user-layout/transverse/delays/'
    | '/_user-layout/transverse/incidents/'
    | '/_user-layout/human-resources/drivers/$driverId/'
    | '/_user-layout/journey-management/delivery-request/$requestId/'
    | '/_user-layout/journey-management/drivers-pools/$driverPoolId/'
    | '/_user-layout/journey-management/modifications/history/'
    | '/_user-layout/materials/vehicles/$vehicleId/'
    | '/_user-layout/sales/companies/$companyId/'
    | '/_user-layout/sales/delivery-request/$requestId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticationLayoutRoute: typeof AuthenticationLayoutRouteWithChildren
  UserLayoutRoute: typeof UserLayoutRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticationLayoutRoute: AuthenticationLayoutRouteWithChildren,
  UserLayoutRoute: UserLayoutRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authentication-layout",
        "/_user-layout"
      ]
    },
    "/_authentication-layout": {
      "filePath": "_authentication-layout.tsx",
      "children": [
        "/_authentication-layout/login/",
        "/_authentication-layout/verify/"
      ]
    },
    "/_user-layout": {
      "filePath": "_user-layout.tsx",
      "children": [
        "/_user-layout/",
        "/_user-layout/profile/",
        "/_user-layout/settings/",
        "/_user-layout/administration/users/",
        "/_user-layout/human-resources/absences/",
        "/_user-layout/human-resources/driver-certifications/",
        "/_user-layout/human-resources/drivers/",
        "/_user-layout/incidents-management/damages/",
        "/_user-layout/incidents-management/delays/",
        "/_user-layout/incidents-management/incidents/",
        "/_user-layout/incidents-management/infractions/",
        "/_user-layout/journey-management/calendar/",
        "/_user-layout/journey-management/delivery-request/",
        "/_user-layout/journey-management/drivers-pools/",
        "/_user-layout/journey-management/journeys/",
        "/_user-layout/journey-management/live/",
        "/_user-layout/journey-management/modifications/",
        "/_user-layout/journey-management/replacements/",
        "/_user-layout/materials/equipment/",
        "/_user-layout/materials/trailers/",
        "/_user-layout/materials/vehicles/",
        "/_user-layout/sales/additional-services/",
        "/_user-layout/sales/companies/",
        "/_user-layout/sales/delivery-request/",
        "/_user-layout/sales/invoices/",
        "/_user-layout/sales/kpi/",
        "/_user-layout/sales/stats/",
        "/_user-layout/transverse/actions/",
        "/_user-layout/transverse/cnr/",
        "/_user-layout/transverse/damages/",
        "/_user-layout/transverse/delays/",
        "/_user-layout/transverse/incidents/",
        "/_user-layout/human-resources/drivers/$driverId/",
        "/_user-layout/journey-management/delivery-request/$requestId/",
        "/_user-layout/journey-management/drivers-pools/$driverPoolId/",
        "/_user-layout/journey-management/modifications/history/",
        "/_user-layout/materials/vehicles/$vehicleId/",
        "/_user-layout/sales/companies/$companyId/",
        "/_user-layout/sales/delivery-request/$requestId/"
      ]
    },
    "/_user-layout/": {
      "filePath": "_user-layout/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_authentication-layout/login/": {
      "filePath": "_authentication-layout/login/index.lazy.tsx",
      "parent": "/_authentication-layout"
    },
    "/_authentication-layout/verify/": {
      "filePath": "_authentication-layout/verify/index.lazy.tsx",
      "parent": "/_authentication-layout"
    },
    "/_user-layout/profile/": {
      "filePath": "_user-layout/profile/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/settings/": {
      "filePath": "_user-layout/settings/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/administration/users/": {
      "filePath": "_user-layout/administration/users/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/human-resources/absences/": {
      "filePath": "_user-layout/human-resources/absences/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/human-resources/driver-certifications/": {
      "filePath": "_user-layout/human-resources/driver-certifications/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/human-resources/drivers/": {
      "filePath": "_user-layout/human-resources/drivers/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/incidents-management/damages/": {
      "filePath": "_user-layout/incidents-management/damages/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/incidents-management/delays/": {
      "filePath": "_user-layout/incidents-management/delays/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/incidents-management/incidents/": {
      "filePath": "_user-layout/incidents-management/incidents/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/incidents-management/infractions/": {
      "filePath": "_user-layout/incidents-management/infractions/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/calendar/": {
      "filePath": "_user-layout/journey-management/calendar/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/delivery-request/": {
      "filePath": "_user-layout/journey-management/delivery-request/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/drivers-pools/": {
      "filePath": "_user-layout/journey-management/drivers-pools/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/journeys/": {
      "filePath": "_user-layout/journey-management/journeys/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/live/": {
      "filePath": "_user-layout/journey-management/live/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/modifications/": {
      "filePath": "_user-layout/journey-management/modifications/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/replacements/": {
      "filePath": "_user-layout/journey-management/replacements/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/materials/equipment/": {
      "filePath": "_user-layout/materials/equipment/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/materials/trailers/": {
      "filePath": "_user-layout/materials/trailers/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/materials/vehicles/": {
      "filePath": "_user-layout/materials/vehicles/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/additional-services/": {
      "filePath": "_user-layout/sales/additional-services/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/companies/": {
      "filePath": "_user-layout/sales/companies/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/delivery-request/": {
      "filePath": "_user-layout/sales/delivery-request/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/invoices/": {
      "filePath": "_user-layout/sales/invoices/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/kpi/": {
      "filePath": "_user-layout/sales/kpi/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/stats/": {
      "filePath": "_user-layout/sales/stats/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/transverse/actions/": {
      "filePath": "_user-layout/transverse/actions/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/transverse/cnr/": {
      "filePath": "_user-layout/transverse/cnr/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/transverse/damages/": {
      "filePath": "_user-layout/transverse/damages/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/transverse/delays/": {
      "filePath": "_user-layout/transverse/delays/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/transverse/incidents/": {
      "filePath": "_user-layout/transverse/incidents/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/human-resources/drivers/$driverId/": {
      "filePath": "_user-layout/human-resources/drivers/$driverId/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/delivery-request/$requestId/": {
      "filePath": "_user-layout/journey-management/delivery-request/$requestId/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/drivers-pools/$driverPoolId/": {
      "filePath": "_user-layout/journey-management/drivers-pools/$driverPoolId/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/journey-management/modifications/history/": {
      "filePath": "_user-layout/journey-management/modifications/history/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/materials/vehicles/$vehicleId/": {
      "filePath": "_user-layout/materials/vehicles/$vehicleId/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/companies/$companyId/": {
      "filePath": "_user-layout/sales/companies/$companyId/index.lazy.tsx",
      "parent": "/_user-layout"
    },
    "/_user-layout/sales/delivery-request/$requestId/": {
      "filePath": "_user-layout/sales/delivery-request/$requestId/index.lazy.tsx",
      "parent": "/_user-layout"
    }
  }
}
ROUTE_MANIFEST_END */
