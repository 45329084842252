import { Link } from "@tanstack/react-router";

import { useAuth } from "$/lib/providers/AuthProvider";
import { useHeaderContext } from "$/lib/providers/HeaderProvider.tsx";
import { navigationLinks } from "$/lib/utils/constants/misc.constants.ts";
import { cn } from "$/lib/utils/functions/misc.functions";

import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import MainLogo from "../../icons/MainLogo";
import ProfileIcon from "../../icons/ProfileIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import LogoutButton from "./LogoutButton";
import SidebarDeepLink from "./SidebarDeepLink.tsx";
import SidebarLink from "./SidebarLink.tsx";

export default function SideBar() {
  const { toggleSideBar, isSideBarOpen } = useHeaderContext();
  const { role } = useAuth();
  if (!role) return null;

  return (
    <div
      className={cn(
        "sidebar_mask fixed bottom-0 left-0 top-0 z-layout-max flex h-full w-full -translate-x-full duration-300 ease-out md:w-64 md:translate-x-0",
        isSideBarOpen && "translate-x-0",
        !isSideBarOpen && "ease-in md:delay-0 md:duration-0",
      )}
    >
      <nav className="top-0 flex h-full w-1/2 flex-col items-center gap-12 bg-black pt-12 text-snow md:w-64">
        <div className="flex items-center gap-4">
          <button
            type="button"
            onClick={toggleSideBar}
            className="rounded-circle bg-snow p-1 text-black md:hidden"
          >
            <ArrowLeftIcon width={18} height={18} />
          </button>

          <Link to="/">
            <MainLogo />
          </Link>
        </div>

        <div className="mb-11 flex size-full flex-col justify-between overflow-y-auto">
          <div className="overflow-y-auto pb-8 scrollbar-none">
            {navigationLinks.map(
              (link) =>
                link.allowedRoles.includes(role) && (
                  <SidebarLink
                    key={link.path}
                    closeSidebar={toggleSideBar}
                    role={role}
                    {...link}
                  />
                ),
            )}
          </div>

          <div className="space-y-3">
            {/* separator */}
            <div className="mx-3 h-0.5 rounded-pill bg-snow md:mx-4" />
            <SidebarDeepLink
              allowedRoles={["ADMIN", "CONCEPTOR", "OPERATOR", "SALES"]}
              path="/profile"
              label="Profil"
              icon={ProfileIcon}
              closeSidebar={toggleSideBar}
            />
            <SidebarDeepLink
              allowedRoles={["ADMIN", "CONCEPTOR", "OPERATOR", "SALES"]}
              path="/settings"
              label="Paramètres"
              icon={SettingsIcon}
              closeSidebar={toggleSideBar}
            />
            <LogoutButton />
          </div>
        </div>
      </nav>
      <button
        type="button"
        onClick={toggleSideBar}
        className="h-full w-1/2 md:hidden"
      />
    </div>
  );
}
