import { Link, useLocation } from "@tanstack/react-router";

import type { NavigationLink } from "$/lib/utils/constants/misc.constants";
import { cn } from "$/lib/utils/functions/misc.functions";

export default function SidebarDeepLink({
  label,
  path,
  icon: Icon,
  parentPath,
  closeSidebar,
}: Omit<NavigationLink & { parentPath?: string }, "deepLinks">) {
  const { pathname } = useLocation();
  const isActive = pathname.startsWith(
    parentPath ? `${parentPath}${path}` : path,
  );

  return (
    <Link
      to={parentPath ? `${parentPath}${path}` : path}
      onClick={closeSidebar}
      className={cn(
        "relative flex h-10 w-full items-center justify-start gap-3 px-3 text-xs text-grey delay-100 duration-200 md:gap-4 md:px-6 md:text-sm",
        isActive && "text-snow",
      )}
    >
      <span>
        <Icon />
      </span>
      <span className="flex-1">{label}</span>

      <div
        className={cn(
          "absolute right-0 h-full w-1.5 origin-right scale-x-0 rounded-l-sm bg-primary duration-200",
          isActive && "scale-x-100",
        )}
        style={{
          transitionDelay: isActive ? "0.1s" : "0s",
        }}
      />
    </Link>
  );
}
